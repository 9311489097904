<template>
    <div class="pt-3 pl-5 pr-5 overflow-y-hidden">
        <div class="row w-full justify-between flex" style="align-items: center">
            <h1 class="title">Systems</h1>
            <div class="cardbody">
                <div class="flex flex-row-reverse items-center">
                    <div class="flex flex-row-reverse items-center">
                        <button class="flex items-center" @click="filterOpen" :class="systemsArray.length == 0?'btndisabled pointer-events-none':'filterButton h-8'">
                            Filters
                            <img src="@/assets/filter.svg" class="h-3 ml-2.5" />
                        </button>
                    </div>
                    <div class="text-center">
                      <button class="btnprimary mr-2" @click="showAddPopup()">Add</button>
                   </div>
                </div>
            </div>
        </div>
        <div class="my-3 text-xs">
            Risk management is the process of identifying, assessing and controlling financial, legal, strategic and security risks to an organization’s capital and earnings. These threats, or risks, could stem from a wide variety of sources, including financial uncertainty, legal liabilities,
            strategic management errors, accidents and natural disasters.To reduce risk, an organization needs to apply resources to minimize, monitor and control the impact of negative events while maximizing positive events. A consistent, systemic and integrated approach to risk management can
            help determine how best to identify, manage and mitigate significant risks.
        </div>
        
        <div>
            <newTable :tableRowData="systemsArray" :headersData="headers" :tableProps="tableProps" :openPopup="false" :navigate="true" :loadData="dataLoading"></newTable>

            <div v-if="toolTipActive">
                <tool-tip :tipText="toolTipText"></tool-tip>
            </div>
        </div>


        <popup v-if="showSystemPopup==true" :popUp="showSystemPopup">
            <template v-slot:header>
                <div class="flex items-center justify-between popupheadingcontainer rounded">
                    <h1>System</h1>
                    <button class="float-right text-3xl" @click="onCancelbtn('close')">
                        <img src="@/assets/cancel.svg" class="h-5 w-5 cursor-pointer" />
                    </button>
                </div>
            </template>
            <fieldset>
                <div>
                <div>
                    <div class="statuscontainer popupbodycontainer">
                        <div class="formcontainer flex flex-col">
                            <div class="row flex flex-row w-full upperspacing">
                                <div class="w-6/12">
                                    <label class="controllabel">Name</label>
                                    <input
                                        type="text"
                                        class="inputboxstyling"
                                        v-model.trim="mainSystem.systemName"
                                        placeholder="System Name"
                                        @input="v$.mainSystem.systemName.$touch()"
                                        :class="{
                                            'is-invalid': (v$.mainSystem.systemName.$error || checkSystemName)
                                        }"
                                    />
                                    <div v-if="v$.mainSystem.systemName.$error || checkSystemName" class="text-red-500">
                                        <div class="error-text text-xs" v-if="v$.mainSystem.systemName.required.$invalid">Required</div>
                                        <div v-else-if="v$.mainSystem.systemName.required && v$.mainSystem.systemName.restrictNames.$invalid" class="error-text text-xs">Charcter Limit is exceeded</div>
                                        <div v-else-if="checkSystemName" class="invalid-feedback text-xs">System Name already exists.</div>
                                    </div>
                                </div>
                                <div class="w-6/12 leftspacing">
                                    <label class="controllabel">Category</label>
                                    <!-- <input
                                        type="text"
                                        class="inputboxstyling"
                                        v-model.trim="mainSystem.systemCategory"
                                        placeholder="System Category"
                                        @input="v$.mainSystem.systemCategory.$touch()"
                                        :class="{
                                            'is-invalid': (v$.mainSystem.systemCategory.$error)
                                        }"
                                    /> -->
                                    <multiselect
                                        v-model.trim="mainSystem.systemCategory"
                                        :options="systemCategoryDisplay"
                                        @search-change="asyncSystemCategoryDataFind"
                                        @select="checkCategoryLength"
                                        :multiple="false"
                                        :searchable="true"
                                        :hide-selected="true"
                                        open-direction="bottom"
                                        :close-on-select="true"
                                        :clear-on-select="true"
                                        :hideArrow="false"
                                        placeholder="System Category"
                                        deselectLabel=""
                                        :showNoOptions="false"
                                        selectLabel=""
                                        class="custom-multiselect userSelect"
                                        :class="{
                                            'is-invalid borderRed': v$.mainSystem.systemCategory.$error || displayCategoryLengthErrorMessage,
                                            'has-options': systemCategoryDisplay && systemCategoryDisplay.length > 0,
                                            'no-options': !systemCategoryDisplay.length &&  searchValue !== '',
                                        }"
                                    >
                                    </multiselect>
                                    <div v-if="v$.mainSystem.systemCategory.$error " class="text-red-500">
                                        <div class="error-text text-xs" v-if="v$.mainSystem.systemCategory.required.$invalid">Required</div>
                                        <div v-else-if="v$.mainSystem.systemCategory.required && v$.mainSystem.systemCategory.restrictNames.$invalid" class="error-text text-xs">Charcter Limit is exceeded</div>
                                    </div>
                                    <div v-else-if="displayCategoryLengthErrorMessage">
                                        <div class="text-error text-xs">Charcter Limit is exceeded</div>
                                    </div>
                                </div>
                            </div>
                            <div class="w-full upperspacing">
                                <label class="controllabel">Description</label>
                                <textarea
                                    type="text"
                                    class="inputboxstyling"
                                    v-model.trim="mainSystem.systemDescription"
                                    placeholder="Description"
                                    rows="3"
                                    @input="v$.mainSystem.systemDescription.$touch()"
                                    :class="{
                                        'is-invalid': (v$.mainSystem.systemDescription.$error)
                                    }"
                                />
                                <div v-if="v$.mainSystem.systemDescription.$error" class="text-red-500">
                                    <div class="error-text text-xs" v-if="v$.mainSystem.systemDescription.required.$invalid">Required</div>
                                    <div v-else-if="v$.mainSystem.systemDescription.required && v$.mainSystem.systemDescription.restrictCharacters.$invalid" class="error-text text-xs">Charcter Limit is exceeded</div>
                                </div>
                            </div>
                            <div class="row flex flex-row w-full upperspacing">
                                <div class="col-md-6 w-6/12">
                                    <label class="controllabel">Model</label>
                                    <select
                                        v-model="mainSystem.model"
                                        :class="{
                                            'is-invalid': v$.mainSystem.model.$error,
                                            'inputboxstylingForSelectAtPopup': mainSystem.model == '',
                                            'stylingForInputboxstylingForSelectAtPopup': mainSystem.model != ''
                                        }"
                                        >
                                        <option value="" selected disabled hidden>Please select one</option>
                                        <option class="optionColors" v-for="name in systemModels" :key="name" :value="name">{{name}}</option>
                                    </select>
                                    <div v-if="v$.mainSystem.model.$error" class="text-red-500">
                                        <div class="error-text text-xs" v-if="v$.mainSystem.model.required.$invalid">Required</div>
                                    </div>
                                </div>

                                <div class="col-md-6 w-6/12 leftspacing">
                                    <label class="controllabel">Add (URL / IP Address / Domain Name)</label>
                                    <input
                                        type="text"
                                        class="inputboxstyling"
                                        v-model.trim="mainSystem.url"
                                        placeholder="Url"
                                        @input="v$.mainSystem.url.$touch()"
                                        :class="{
                                            'is-invalid': (v$.mainSystem.url.$error||checkwebsiteDomain)
                                        }"
                                    />
                                    <div v-if="v$.mainSystem.url.$error" class="text-red-500">
                                        <div class="error-text text-xs" v-if="v$.mainSystem.url.required.$invalid">Required</div>
                                        <div v-else-if="v$.mainSystem.url.required && v$.mainSystem.url.restrictCharacters100.$invalid" class="error-text text-xs">Charcter Limit is exceeded</div>
                                        <div v-else-if="v$.mainSystem.url.required &&  v$.mainSystem.url.websiteValidateWithIp.$invalid" class="error-text text-xs">Invalid Website</div>
                                    </div>
                                    <div v-if="checkwebsiteDomain" class="text-red-500">
                                        <div class="error-text text-xs">This url already exists</div>
                                    </div>
                                </div>
                            </div>
                            <div class="row flex w-full upperspacing">
                                <div class="col-md-6 w-6/12">
                                    <label class="controllabel">Vendor Category</label>
                                    <multiselect
                                        v-model="mainSystem.vendorCategory"
                                        :options="vendorCategoryDisplayList" 
                                        :searchable="true"  
                                        :close-on-select="true"
                                        :show-labels="false"
                                        :hide-selected="true"
                                        placeholder="Vendor Category"
                                        open-direction="bottom"
                                        @select="getVendorsListBasedOnCategory()"
                                        class="custom-multiselect userSelect"
                                        :class="{
                                            'is-invalid borderRed': v$.mainSystem.vendorCategory.$error,
                                            'has-options': vendorCategoryDisplayList && vendorCategoryDisplayList.length > 0,
                                            'no-options': !vendorCategoryDisplayList.length,
                                        }"
                                    >
                                        <template v-slot:noResult>
                                            <span class="text-xs">No Category Found</span>
                                        </template>
                                    </multiselect>
                                    <div v-if="v$.mainSystem.vendorCategory.$error" class="text-red-500">
                                        <div class="error-text text-xs" v-if="v$.mainSystem.vendorCategory.required.$invalid">Required</div>
                                    </div>
                                </div>
                                <div class="col-md-6 w-6/12 leftspacing">
                                    <label class="controllabel">Vendor</label>

                                    <multiselect
                                        v-model="mainSystem.vendor"
                                        :options="vendorDisplayList" 
                                        :searchable="true"
                                        @search-change="asyncFindVendor"
                                        :disabled="mainSystem.vendorCategory==''"  
                                        :close-on-select="true"
                                        :show-labels="false"
                                        :hide-selected="true"
                                        placeholder="Vendor"
                                        open-direction="bottom"
                                        :multiple="false"
                                        :showNoOptions="false"
                                        :clear-on-select="true"
                                        label="label"
                                        track-by="value"   
                                        :hideArrow="false"
                                        class="custom-multiselect userSelect"
                                        :class="{
                                            'is-invalid borderRed': v$.mainSystem.vendor.$error,
                                            'has-options': vendorDisplayList && vendorDisplayList.length > 0,
                                            'no-options': !vendorDisplayList.length &&  searchValue !== '',
                                        }"
                                    >
                                        <template v-slot:noResult>
                                            <span class="text-xs">No Vendors Found</span>
                                        </template>
                                    </multiselect>
                                    <div v-if="v$.mainSystem.vendor.$error" class="text-red-500">
                                        <div class="error-text text-xs" v-if="v$.mainSystem.vendor.required.$invalid">Required</div>
                                    </div>
                                </div>
                            </div>
                            
                            <div class="row w-full upperspacing">
                                <label class="controllabel">Logo</label>
                                <div v-if="mainSystem.imageUrl == '' && !mainSystem._id" :class="{'is-invalid borderRed': v$.fileName.$error,}" class="border w-full rounded py-2  border-lightgrey">
                                    <div class="flex justify-center">
                                        <div>
                                            <div class="flex justify-center">
                                                <label :htmlFor="mainSystem" class="mt-2">
                                                    <img src="@/assets/addImage.svg" alt="" class="w-20 h-20 cursor-pointer" />
                                                </label>
                                                <label :htmlFor="mainSystem">
                                                    <input type="file" :id="mainSystem" style="display: none" ref="fileInput" @change.stop="imageCheckFile('fileInput','add')"/>
                                                </label>
                                            </div>
                                            <div class="flex justify-center items-center mt-2">
                                                <div class="text-xs" v-if="fileName!=''">Selected File :  <span class="text-xs font-bold"> {{fileName}}</span>	</div>
                                                <div v-else-if="fileName == ''" class="text-xs">{{'Click on the icon for uploading a file'}}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-else-if="mainSystem._id && mainSystem.imageUrl !=''" class="border w-full rounded py-2  border-lightgrey">
                                    <div class="flex justify-center p-2 items-center">
                                        <label :htmlFor="mainSystem" class="mt-2">
                                            <img :src="mainSystem.imageUrl" alt="" class="w-20 h-20 cursor-pointer" />
                                        </label>
                                        <label :htmlFor="mainSystem">
                                            <input type="file" :id="mainSystem" style="display: none" ref="fileInput" @change.stop="imageCheckFile('fileInput','edit')"/>
                                        </label>
                                        
                                    </div>
                                    <div class="flex justify-center items-center mt-2">
                                        <div class="text-xs" v-if="fileName!=''">Selected File :  <span class="text-xs font-bold"> {{fileName}}</span>	</div>
                                    </div>
                                </div>
                                <div v-if="v$.fileName.$error" class="text-red-500">
                                    <div class="error-text text-xs" v-if="v$.fileName.required.$invalid">Required</div>
                                </div>
                            </div>

                            <div class="flex justify-center items-center mt-4 mx-0">
                                <button :disabled="clickOnButton" :class="JSON.stringify(mainSystem) == JSON.stringify(this.dummySystem) ? 'btndisabled pointer-events-none' :'btnprimary'" class="btnprimary mr-1.5"  @click="onCancelbtn('cancel')">Cancel</button>
                                <button :disabled="clickOnButton" :class="JSON.stringify(mainSystem) == JSON.stringify(this.dummySystem) ? 'btndisabled pointer-events-none' :'btnprimary'" class="btnprimary mr-1.5" v-if="!mainSystem._id" @click="addSystem('add')">Save</button>
                                <button :disabled="clickOnButton" :class="JSON.stringify(mainSystem) == JSON.stringify(this.dummySystem) ? 'btndisabled pointer-events-none' :'btnprimary'" class="btnprimary mr-1.5" @click="editSystem('update')" v-else >Update</button>
                            </div>
                            
                    </div>
                    </div>
                </div>
                <div>
            
                </div>
            </div>
            </fieldset>
        </popup>

    </div>
    <spinner v-if="spinnerLoading" />
</template>

<style>
.inputboxstylingForSelectAtPopup .optionColors{
   color : #4D4D4D !important;
}
.borderRed{
    border: 1px solid red !important;
}
</style>

<script lang="ts">
import { defineComponent } from 'vue';
import newTable from '@/components/newTable.vue';
import toolTip from '@/components/toolTip.vue';
import { emitter, toast } from '@/main';
import popup from '@/components/sidePopup.vue';
import multiselect from 'vue-multiselect';
import useVuelidate from '@vuelidate/core';
import { required } from '@vuelidate/validators';
import { helpers } from '@vuelidate/validators';
const restrictCharacters = helpers.regex(/^.{0,300}$/);
const restrictNames = helpers.regex(/^.{0,20}$/);
import { v4 as uuidv4 } from 'uuid';
import spinner from '@/components/spinner.vue';
const restrictCharacters100 = helpers.regex(/^.{0,100}$/);
// const websiteValidateWithIp = helpers.regex(/^((http|https):\/\/)?(www\.)+[a-zA-Z0-9-]+\.[a-zA-Z]{2,}$/);
const websiteValidateWithIp = helpers.regex(/^(https?:\/\/)?(www\.)?((?!www)[a-zA-Z0-9-]{2,256}\.(?!www)[a-z]{2,20}(\.(?!www)[a-z]{2,20}){0,2}|((25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?))(:(?:[0-5]?[0-9]{1,4}|[1-5][0-9]{1,3}|655[0-3][0-5]))?(\/[a-zA-Z0-9\-._~:/#\\]*)?$/g);

export default defineComponent({
    data() :any{
        return {
            v$: useVuelidate(),
            show:false,
            spinnerLoading: false,
            systemsArray:[],
            headers:['systemName','systemDescription','systemCategory','vendorName'],
            dataLoading:true,
            toolTipText:'',
            toolTipActive:false,
            dummyList:[],
            showFilters:false,
            mainSystem: {
                systemName:'',
                systemCategory:'',
                model:'',
                url:'',
                systemDescription:'',
                imageUrl:'',
                vendor:'',
                vendorCategory:'',
            },
            tableProps:{
                headerNames:[{key:'systemName',value:'Name'},{key:'systemCategory',value:'Category'},{key:'systemDescription',value:'Description'},{key:'vendorName',value:'Vendor'}],
                
                headerWidth:[{key:'systemName',value:'w-1/5'},{key:'systemCategory',value:'w-1/5'},{key:'systemDescription',value:'w-2/5'},{key:'vendorName',value:'w-1/5'}],
            },
            showSystemPopup:false,
            systemModels:['IaaS','SaaS','PaaS'],
            vendorDisplayList:[],
            vendorList:[],
            allVendorList:[],
            vendorLabelList:[],
            vendorCategoryDisplayList:['sample1','sample2','sample3'],
            searchValue:'',
            fileName:'',
            dummySystem:{},
            existingSystemNames:[],
            filteredSystemNames:[],
            presentFile:'',
            presentImageUrl:'',
            clickOnButton: false,

            systemCatgeoryList:[],
            systemCategoryDisplay:[],
            allwebsites:[],
            websiteExcludingSelectedWebsite:[],
            displayCategoryLengthErrorMessage : false
        }
    },
    validations() {
        return {
            mainSystem: {
                systemName:{ required,restrictNames },
                systemCategory:{ required,restrictNames },
                model:{ required },
                url:{ required ,restrictCharacters100,websiteValidateWithIp},
                systemDescription:{ required,restrictCharacters },
                vendor:{ required },
                vendorCategory:{ required },
            },

            fileName:{required}
        };
    },
    components:{
        toolTip,
        newTable,
        popup,
        multiselect,
        spinner
    },
    computed:{
        checkSystemName: function (): any {
            const trimmedSystemName = this.mainSystem.systemName.replace(/\s+/g, '').trim().toLowerCase();
 
            if(this.existingSystemNames == null || this.existingSystemNames == undefined || this.existingSystemNames.length == 0){
                return false
            }
             
            if(this.mainSystem._id){
                let a = false;
                a = this.filteredSystemNames.map((name: any) => name.replace(/\s+/g, '').toLowerCase()).includes(trimmedSystemName);
                return a
               
            }else{
                let a = false;
                a = this.existingSystemNames.map((name: any) => name.replace(/\s+/g, '').toLowerCase()).includes(trimmedSystemName);
                return a
            }
        },
        checkwebsiteDomain: function (): any {
            const trimmedSystemurl = this.mainSystem.url.trim().toLowerCase();

            if(this.allwebsites == null || this.allwebsites == undefined || this.allwebsites.length == 0){
                return false
            }
             
            if(this.mainSystem._id){
                let a = false;
                a = this.websiteExcludingSelectedWebsite.map((name: any) => name.toLowerCase()).includes(trimmedSystemurl);
                return a
                
            }else{
                let a = false;
                a = this.allwebsites.map((name: any) => name.toLowerCase()).includes(trimmedSystemurl);
                return a
            }
        }
    },
    methods:{
        asyncSystemCategoryDataFind(query:any) {
            this.searchValue = query
			query = query.trim();
			if (query == '') {
				this.systemCategoryDisplay = [];
			} else {
				this.systemCategoryDisplay = this.systemCatgeoryList.filter((item:any) => 
					item?.toLowerCase().includes(query.toLowerCase())
				);
				let isNewOption = !this.systemCatgeoryList.some((item:any) => 
					item?.toLowerCase() === query.toLowerCase()
				);
				if (isNewOption && query !== '') {
					this.systemCategoryDisplay.push(query);
				}
			}
		},
        getVendorsListBasedOnCategory(){
            this.vendorList = [];
            this.allVendorList.map((obj:any)=>{
                if(obj.vendorCategory == this.mainSystem.vendorCategory){
                    let a = {value:obj._id,label:obj.name};
                    this.vendorList.push(a)
                }
            })

            if(this.mainSystem._id){
                this.mainSystem.vendor = ''
            }
        },
        checkCategoryLength(){
            if(this.mainSystem.systemCategory.length > 20){
                this.displayCategoryLengthErrorMessage = true
            } else {
                this.displayCategoryLengthErrorMessage = false
            }
        },
        asyncFindVendor(query:any){
            this.searchValue = query
			if(query == ''){
				this.vendorDisplayList = []
			}else if(query.length > 0){
				this.vendorDisplayList = this.vendorList.filter((obj:any)=>{return obj.label.toLowerCase().includes(query.toLowerCase())})
			}
        },
       filterOpen() {
            this.showFilters = !this.showFilters;
            if (this.showFilters == true) {
                this.dummyList = this.systemsArray;
            } else {
                this.systemsArray = this.dummyList;
            }
            emitter.emit('visible',this.showFilters)
        },
        showAddPopup(){
            this.showSystemPopup = true;
            this.mainSystem = {
                systemName:'',
                systemCategory:'',
                model:'',
                url:'',
                systemDescription:'',
                imageUrl:'',
                vendor:'',
                vendorCategory:'',
            };
            this.dummySystem = {...this.mainSystem}
        },
        showEditPopup(data:any){
            this.$router.push({ name: 'onBoardSystemInformation', params: { systemId: data._id } });
            // this.mainSystem = {...data};
           
            // this.dummySystem = {...data};

            // this.filteredSystemNames = this.existingSystemNames.filter((name:any)=>{
            //     return name != this.mainSystem.systemName
            // })

            // this.presentImageUrl = this.mainSystem.imageUrl

            // this.showSystemPopup = true;
        },
        async addSystem(type:any){
            this.v$.$touch();

            if (!this.v$.mainSystem.$invalid && !this.v$.fileName.$invalid  && this.checkSystemName == false && this.checkwebsiteDomain == false && this.displayCategoryLengthErrorMessage == false) {
                
                let payload:any = {
                    "name":this.mainSystem.systemName,
                    "systemCategory":this.mainSystem.systemCategory,
                    "description":this.mainSystem.systemDescription,
                    "model":this.mainSystem.model,
                    "url":this.mainSystem.url,
                    "vendorCategory":this.mainSystem.vendorCategory,
                    "vendor":this.mainSystem.vendor.value,
                    "imageUrl":""
                }

                await this.uploadFileToS3(this.presentFile);

                payload.imageUrl = this.presentImageUrl;

                await this.addNewSystem(payload);
                
            }else{
                console.log('this.v$.system.$invalid',!this.v$.mainSystem.$invalid)
                console.log('this.checkSystemName == false',this.checkSystemName == false)
            }

        },

        async editSystem(type:any){
            this.v$.mainSystem.$touch();

            if (!this.v$.mainSystem.$invalid  && this.checkSystemName == false && this.displayCategoryLengthErrorMessage == false) {

                let presentId = this.mainSystem._id;
                
                let payload:any = {
                    "name":this.mainSystem.systemName,
                    "systemCategory":this.mainSystem.systemCategory,
                    "description":this.mainSystem.systemDescription,
                    "model":this.mainSystem.model,
                    "url":this.mainSystem.url,
                    "vendorCategory":this.mainSystem.vendorCategory,
                    "vendor":this.mainSystem.vendor.value,
                    "imageUrl": this.mainSystem.imageUrl
                }

                if(Object.keys(this.mainSystem).includes('imageChanged')){
                    await this.uploadFileToS3(this.presentFile);

                    payload.imageUrl = this.presentImageUrl;
                }

                await this.updatePresentSystem(payload,presentId);
                
            }else{
                console.log('this.v$.system.$invalid',!this.v$.mainSystem.$invalid)
                console.log('this.checkSystemName == false',this.checkSystemName == false)
            }

        },

        async updatePresentSystem(payload:any,presentId:any){
            this.clickOnButton = true;
            
            try{
                await this.$http
				.post(`${process.env.VUE_APP_ADMIN_API_URL}/api/root/update/${presentId}/20005`, payload)
				.then(async (response: any) => {
                    this.presentFile = '';
                    this.presentImageUrl  = '';
                    this.onCancelbtn('close')
                    await this.getAllSystemsRoot()
					toast.info('Saved', {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});	
				})
            }catch {
                toast.error('Error', {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            }finally {
                this.clickOnButton = false;
            }
        },

        async addNewSystem(payload:any){
            this.clickOnButton = true;

            try{
                await this.$http
				.post(`${process.env.VUE_APP_ADMIN_API_URL}/api/root/create/20005`, payload)
				.then(async (response: any) => {
                    this.presentFile = '';
                    this.presentImageUrl  = '';

                    await this.getAllSystemsRoot();
                    
                    this.v$.$reset();
                    this.showSystemPopup  = false;
                    this.fileName = '';

					toast.info('Saved', {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				});

            }catch{
                toast.error('Error', {
                    timeout: 1000,
                    closeOnClick: true,
                    closeButton: 'button',
                    icon: true
                });
            }finally{
                this.clickOnButton = false;
            }
            
        },

        async uploadImageContent(data: any) {
			console.log("data",data)
			return new Promise((resolve) => {
				const reader: any = new FileReader();
				reader.onload = function () {
					resolve(reader.result.replace('data:', '').replace(/^.+,/, ''));
				};
				reader.readAsDataURL(data);
			});
		},

        async uploadFileToS3(data: any) {
            this.dataLoading = true;
            this.showSystemPopup = false
			
			const file = await this.uploadImageContent(data);

			let fileExtension = data.name.split('.').pop();
			let id = uuidv4();

			var payload;
			
			payload = { fileData: file, name: `${id}.${fileExtension}` };

			console.log('payload', payload);

			try {
				await this.$http
					.post(`${process.env.VUE_APP_FILE_API_URL}/bucket/${process.env.VUE_APP_FOLDER_NAME}/file/upload`, payload)
					.then((response: any) => {
						if (response.status == 200) {
							console.log('imageUrlimageUrl', response.data);
                            this.presentImageUrl = response.data;
                            this.dataLoading = false;
						} else {
							console.log('response status', response.status);
                            this.presentImageUrl = ''
						}
					});
			} catch (err) {
				console.error(err);
			}
		},
        
        imageCheckFile(fileInput:any,type:any) {
			let file = [];
			this.fileName = '';
			
			file = this.$refs[fileInput].files[0];

            console.log('myfile',file)
			
			if (file != undefined) {
				this.imgCheck(file,type);
			}
			
		},
        imgCheck(file: any,type:any) {
			if (file.type == 'image/jpeg' || file.type == 'image/jpg' || file.type == 'image/png') {
				let size = Number((file.size / 1048576).toFixed(2));
				if (size > 80) {
					toast.error('Image should be atmost 80 MB', {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
					this.resetFileUploads()
				} 
				else {
					this.fileName = file.name;
                    this.presentFile = file;

                    // if(type == 'edit'){
                        this.mainSystem.imageChanged = true
                    // }
				}
			} else {
				toast.error('Supported only jpg, jpeg, png', {
					timeout: 1000,
					closeOnClick: true,
					closeButton: 'button',
					icon: true
				});
				this.resetFileUploads()
			}
		},
        resetFileUploads(){
            if(this.$refs.fileInput.value){
                this.$refs.fileInput.value='';
            }
		},
        onCancelbtn(prop:any){
            this.v$.$reset();
            if(prop == 'close'){
                this.showSystemPopup  = false;
                this.fileName = ''
                this.displayCategoryLengthErrorMessage = false
            }

            if(this.fileName != ''){
                this.fileName = ''
            }
            this.displayCategoryLengthErrorMessage = false
            this.resetFileUploads()
            this.mainSystem = {...this.dummySystem};
        },
        async getAllSystemsRoot(){
            this.allwebsites = [];
            this.websiteExcludingSelectedWebsite = [];
            this.existingSystemNames = [];
            this.systemsArray = [];
            this.dummyList = [];
            this.systemCatgeoryList = [];

            await this.$http
				.get(`${process.env.VUE_APP_ADMIN_API_URL}/api/root/getall/20005`)
				.then((response: any) => {
                    
                    response.data.map((obj:any)=>{
                        let b = this.vendorLabelList.find((item:any)=>{return item.value == obj.vendor})
                        let a = {
                            _id:obj._id,
                            systemName:obj.name,
                            systemCategory:obj.systemCategory,
                            model:obj.model,
                            url:obj.url,
                            systemDescription:obj.description,
                            imageUrl:obj.imageUrl,
                            vendor: b==undefined ? 'N/A' : b,
                            vendorName: b==undefined ? 'N/A' : b.label ,
                            vendorCategory:obj.vendorCategory,
                        };
                        if(!this.systemCatgeoryList.includes(obj.systemCategory)){
                            this.systemCatgeoryList.push(obj.systemCategory)
                        }
                        this.systemsArray.push({...a});
                        this.dummyList.push({...a})
                    })

                    this.systemsArray.map((obj:any)=>{
                        this.existingSystemNames.push(obj.systemName)
                        this.allwebsites.push(obj.url)
                    })
				})
				.catch((error: any) => {
					toast.error('Error', {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				});
        },
        async getAllVendors(){
            this.vendorCategoryDisplayList = [];
            this.vendorList = [];
            this.vendorLabelList = []

            await this.$http
				.get(`${process.env.VUE_APP_ADMIN_API_URL}/api/root/getall/20003`)
				.then((response: any) => {
                    
                    let arr:any = response.data;

                    this.allVendorList = arr;

                    arr.map((item:any)=>{
                        this.vendorCategoryDisplayList.push(item.vendorCategory);
                        let a = {value:item._id,label:item.name};
                        this.vendorLabelList.push(a)
                    })
				})
				.catch((error: any) => {
					toast.error('Error', {
						timeout: 1000,
						closeOnClick: true,
						closeButton: 'button',
						icon: true
					});
				});
        }
    },
    async created(){
        this.dataLoading = true
        await this.getAllVendors()
        await this.getAllSystemsRoot()
        this.dataLoading = false;
    },
    async mounted(){

        emitter.off('dataTransfering');
        emitter.on('dataTransfering', (isData: any) => {
            console.log('cameobject',isData)
            this.showEditPopup(isData)
        })
        
        emitter.off('activiteToolTip');
        emitter.on('activiteToolTip', (data: any) => {
            this.toolTipActive = true;
            this.toolTipText = data.tipText
        });
 
        emitter.off('deActiviteToolTip');
        emitter.on('deActiviteToolTip', (data: any) => {
            this.toolTipActive = false;
            this.toolTipText = ''
        });
    }
})
</script>
